import styled from 'styled-components';

const ProgressBar = styled.progress`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 20px;
  border: none;

  &[value]::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  }

  &[value]::-webkit-progress-value {
    background-color: #092ff4;
    transition: all ease-in-out 0.25s;
  }

  &[value]::-moz-progress-bar {
    background-color: #092ff4;
    transition: all ease-in-out 0.25s;
  }
`;

export default ProgressBar;
