import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from '../atoms/Button';
import PropTypes from 'prop-types';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ProgressBar from './progressBarStyles';

const Dropzone: ObsoleteComponent = ({ setFileInformation, resetDropZone }) => {
  const [fileUploaded, setFileUploaded] = useState<File[]>([]);
  const [fileUploadState, setFileUploadedState] = useState('idle');
  const [fileUploadPercentage, setFileUploadPercentage] = useState(0);
  const acceptedFileTypes = useMemo(
    () => [
      'image/pjpeg',
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    [],
  );

  //Reset File Upload
  useEffect(() => {
    return resetDropZone && resetAll();
  }, [resetDropZone]);

  //Simulate upload
  useEffect(() => {
    let timer;
    let delay;

    const getRandomInt = (max) => {
      return Math.floor(Math.random() * max) + 1;
    };
    delay = getRandomInt(3) * 10;

    if (fileUploadState === 'uploading' && fileUploadPercentage <= 100) {
      timer = setInterval(() => setFileUploadPercentage(fileUploadPercentage + 1), delay);
    } else {
      setFileUploadedState('idle');
    }

    return () => {
      clearTimeout(timer);
    };
  }, [fileUploadState, fileUploadPercentage]);

  //Set the File Information once upload and simulate upload is complete
  useEffect(() => {
    if (fileUploaded?.length > 0 && fileUploadPercentage === 100) {
      setFileInformation(fileUploaded[0]);
    }
  }, [fileUploaded, fileUploadPercentage, setFileInformation]);

  const resetAll = (e) => {
    return (
      e?.preventDefault(),
      setFileUploadedState('idle'),
      setFileUploaded([]),
      setFileUploadPercentage(0)
    );
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFileTypes.includes(acceptedFiles[0]?.type)) {
        setFileUploaded(acceptedFiles);
        setFileUploadedState('uploading');
      }
    },
    [acceptedFileTypes],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: acceptedFileTypes.toString(),
  });

  return (
    <div {...getRootProps()} className="text-center w-full relative">
      <svg
        className="hidden lg:block m-auto mb-4 mt-8"
        width="50"
        height="50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M39.96 13.45a14.825 14.825 0 00-4.855-9.585A15.115 15.115 0 0025 0a15.115 15.115 0 00-10.104 3.865 14.825 14.825 0 00-4.856 9.585 12.539 12.539 0 00-7.586 4.781A12.307 12.307 0 00.06 26.813a12.354 12.354 0 004.033 7.957 12.595 12.595 0 008.38 3.23c.443 0 .868-.174 1.181-.484a1.644 1.644 0 000-2.338 1.678 1.678 0 00-1.18-.484 9.232 9.232 0 01-6.3-2.577 9.05 9.05 0 01-2.77-6.164 9.036 9.036 0 012.283-6.357 9.217 9.217 0 016.08-3.05c.424-.04.816-.236 1.101-.548a1.68 1.68 0 00.44-1.14c-.008-1.52.286-3.027.866-4.434a11.562 11.562 0 012.514-3.767 11.749 11.749 0 018.247-3.433c3.1-.017 6.08 1.187 8.284 3.345a11.514 11.514 0 013.468 8.164v.165c-.002.418.157.82.443 1.127.286.307.68.495 1.1.527a9.216 9.216 0 016.048 3.062 9.037 9.037 0 012.268 6.34 9.05 9.05 0 01-2.755 6.148 9.232 9.232 0 01-6.27 2.592c-.442 0-.867.174-1.18.484a1.644 1.644 0 000 2.338c.313.31.738.484 1.18.484a12.595 12.595 0 008.383-3.227 12.354 12.354 0 004.036-7.958 12.307 12.307 0 00-2.393-8.583 12.538 12.538 0 00-7.587-4.782z"
          fill="#000"
        />
        <path
          d="M26.134 18.493A1.566 1.566 0 0025 18c-.425 0-.833.177-1.134.493L17.45 25.23a1.73 1.73 0 00-.45 1.185c.004.441.172.864.47 1.176.297.312.7.49 1.12.493.42.004.826-.166 1.128-.473l3.678-3.861v24.566c0 .447.169.875.47 1.19.3.317.709.494 1.134.494.425 0 .833-.177 1.134-.493.301-.316.47-.744.47-1.191V23.75l3.678 3.861c.302.307.707.477 1.128.473.42-.004.823-.18 1.12-.493.298-.312.466-.735.47-1.176a1.73 1.73 0 00-.45-1.185l-6.416-6.737z"
          fill="#000"
        />
      </svg>
      {fileUploadState === 'idle' && <input {...getInputProps()} />}

      <Button
        label="Choose file to upload"
        className="focus:border-0"
        styleType="secondary"
        onClickHandler={(e) => {
          e.preventDefault();
        }}
      />
      {fileUploaded?.length > 0 && (
        <p>
          {fileUploaded[0].name} - {fileUploaded[0].size / 100}kb
        </p>
      )}
      <p className="hidden lg:block text-xs pt-2 pb-2">or drag a file into the uploader</p>
      <p className=" mt-4 lg:mt-0 text-xs text-gray-400 mb-8">
        .pdf, .doc, .docx, .jpeg, .jpg, .png
      </p>

      {isDragActive && (
        <div className="hidden absolute w-full h-full inset-0 bg-blue-100 lg:flex flex-col items-center justify-center">
          <svg
            className="mb-4"
            width="25"
            height="42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.88 41.2c-.434-.017-.935-.195-1.25-.492L1.329 31.014c-.59-.657-.834-1.917-.152-2.651.673-.723 1.976-.702 2.651.019l7.234 6.816V1.818a1.818 1.818 0 013.635 0v33.38l7.233-6.816c.621-.62 1.971-.735 2.65-.019.68.716.464 2.02-.15 2.65l-10.301 9.695a1.748 1.748 0 01-1.25.492z"
              fill="#000"
            />
          </svg>
          <p>Drop file</p>
        </div>
      )}

      {fileUploadState === 'uploading' && (
        <div className="absolute bg-white w-full h-full inset-0 flex flex-col flex-wrap justify-center">
          <p>Uploading Document</p>
          <div className="flex justify-center w-full items-center ">
            <ProgressBar className="w-9/12" id="fileUpload" max="100" value={fileUploadPercentage}>
              {fileUploadPercentage}%
            </ProgressBar>
            <button className="h-6 w-6 ml-4" onClick={(e) => resetAll(e)}>
              <span className="sr-only">Cancel Upload</span>
              <XMarkIcon aria-hidden="true" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

Dropzone.propTypes = {
  setFileInformation: PropTypes.func,
  resetDropZone: PropTypes.bool,
};

export default Dropzone;
