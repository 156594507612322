import React from 'react';
import LineArt from '../atoms/lineArt';
import { Section } from '../templates/Section';
import { ButtonLink } from '../atoms/Button';
import { InView } from 'react-intersection-observer';
import cn from 'classnames';

type Props = {
  className?: string;
  content: {
    subheader: string;
    header: string;
    url: any;
  };
};

const FooterCTA: React.FC<Props> = ({ content, className }) => {
  const hasSubHeader = content.subheader !== '';

  return (
    <InView triggerOnce threshold={0.75}>
      {({ inView, ref }) => (
        <Section className={cn('bg-blue-700 px-0 xl:px-4', className)}>
          <div
            className="relative col-span-6 xl:col-span-12 w-full max-w-7xl mx-auto grid gap-x-4 grid-cols-6 xl:grid-cols-12 px-0 xl:px-4 py-0 xl:py-3"
            ref={ref}
          >
            <div className="col-span-6 xl:col-start-2 xl:col-end-12">
              <h2
                className={`text-center ${
                  hasSubHeader ? `mb-4` : `mb-12`
                } mt-12 font-serif text-bold text-white text-2rem xl:text-2.5rem`}
              >
                {content.header}
              </h2>
              {hasSubHeader && <h3 className="text-white text-center mb-6">{content.subheader}</h3>}
              <div className="w-full flex justify-center relative z-20">
                <ButtonLink
                  toPage={`/${content?.url?.cached_url}`}
                  type="tertiary"
                  label="Let's go!"
                  size="large"
                />
              </div>
            </div>
            <LineArt placement="topLeft" startAnimation={inView} />
            <LineArt placement="bottomLeft" startAnimation={inView} />
            <LineArt placement="topRight" className="hidden lg:block" startAnimation={inView} />
            <LineArt placement="bottomRight" className="hidden lg:block" startAnimation={inView} />
          </div>
        </Section>
      )}
    </InView>
  );
};

export default FooterCTA;
