import React, { useEffect, useState } from 'react';
import Dropzone from '../components/v1/molecules/dropZone';
import Modal from '../components/v1/modal/index';
import Notification from '../components/v1/molecules/notification';
import queryString from 'query-string';
import { graphql } from 'gatsby';
import FooterCTA from '../components/v1/molecules/footerCTA';
import useStoryblok from '../lib/storyblok';
import Bloks from '../components/atoms/Bloks';
import { StoryblokPage } from '../types';
import Layout from '../components/v2/templates/Layout';
import { Section } from '../components/v2/templates/Section';

const TenantCheck: StoryblokPage = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location);
  const { content } = story;

  const [fileInformation, setFileInformation] = useState({});
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);

  useEffect(() => {
    return Object.keys(fileInformation).length
      ? setIsModalDisplayed(true)
      : setIsModalDisplayed(false);
  }, [fileInformation]);

  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <Bloks bloks={content.SEO} />

      <Section>
        <Bloks bloks={content.hero} />
        <div className="hidden xl:flex col-span-6 xl:col-start-8 xl:col-end-12 flex-col w-full ">
          <em className="not-italic font-bold">Test our Fraud Tech</em>
          <p>Upload a file to see our document analysis.</p>
          <div className="mt-8 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 w-full bg-pink-light p-4">
            <div className="col-span-3 bg-white">
              <div className="w-full flex justify-stretch border-2 border-gray-300 border-dashed">
                <Dropzone
                  setFileInformation={setFileInformation}
                  resetDropZone={isModalDisplayed}
                />
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Bloks bloks={content.sections} />

      <FooterCTA content={content.footerCTA[0]} />
      <Modal
        isDisplayed={isModalDisplayed}
        closeAction={() => setIsModalDisplayed(false)}
        formName="file-upload-form"
        action="/tenant_check/?formSubmit=success"
        headerText="Data Found!"
        fileName={`${fileInformation?.name} - ${fileInformation?.size}`}
      />
      <Notification isVisible={queryString.parse(location.search).formSubmit === 'success'} />
    </Layout>
  );
};

export default TenantCheck;

export const query = graphql`
  query TenantCheckQuery {
    storyblokEntry(full_slug: { eq: "tenant-check" }) {
      content
    }
  }
`;
